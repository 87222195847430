import axios from "axios";

const api = axios.create({
  baseURL:
    process.env.KAMELEON_API ||
    //  "https://dev.web.gateway.kameleon.team/" ||
    "https://api.kameleon.team/",
});

export default api;
